.horizontalScrollContainer::-webkit-scrollbar {
  display: none; /* Optional: Hide scrollbar for Webkit browsers */
}

.carousel {
  position: relative;
  display: flex;
  align-items: center;
}

.scrollContainer {
  display: flex;
  overflow-x: auto;
  gap: 8px; /* Adjust gap between chips */
  padding: 10px 0;
}

/* Hide scrollbar */
.scrollContainer::-webkit-scrollbar {
  display: none;
}

.scrollButton {
  flex-shrink: 0; /* Prevent buttons from shrinking */
}

.scrollContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 768px) {
  .scrollButton {
    width: 30px; /* Smaller buttons on smaller screens */
  }
}
